import moment from 'moment';
import React from 'react';
import {
   Alert,
   Button,
   Col,
   Container,
   Form,
   FormControl,
   Modal,
   Row,
   Spinner,
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import DataGrid, {
   SelectCellFormatter,
   SelectColumn,
   SortableHeaderCell,
   TextEditor,
} from 'react-data-grid';
import { withTranslation } from 'react-i18next';

import { getMyAccountData } from '../actions/account';
import {
   getMyMooringsDetailed,
   getMyMooringTypes,
   getMyMooringZones,
   replaceConfidentialCode,
   updateMooring,
} from '../actions/moorings';
import FormInput from '../components/FormInput';
import COLORS from '../constants/colors';
import globalStyles from '../stylesheets/globalStyles';
import { deepSearchString } from '../utils/utilities';

const i18nPrefix = 'MooringTable';

const dropdown_style = {
   appearance: 'none',
   boxSizing: 'border-box',
   width: '100%',
   height: '100%',
   padding: '0px 6px 0 6px',
   border: '2px solid #ccc',
   verticalAlign: 'top',
   fontFamily: 'inherit',
};

function rowKeyGetter(row) {
   return row.mooring_id;
}

class MooringTalbe extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         success: false,
         error: false,
         moorings: [],
         account: {},
         sortedMoorings: [],
         mooring_types: [],
         mooring_zones: [],
         filters: {},
         columnSortings: [],
         selectedRows: new Set([]),
         selectedMoorings: [],
         editable: false,
         date: moment().format('YYYY-MM-DD'),
         showMassChangeModal: false,
         confidential_mass_change_old: '',
         confidential_mass_change_new: '',
         showEditButtons: false,
      };

      this.csvLink = React.createRef();
      this.searchInput = React.createRef();
   }

   componentDidMount() {
      this.loadMoorings();
      getMyMooringTypes(
         (mooring_types) => {
            this.setState({ mooring_types });
         },
         () => {},
      );
      getMyMooringZones(
         (mooring_zones) => {
            this.setState({ mooring_zones });
         },
         () => {},
      );
      getMyAccountData(
         (account) => this.setState({ account: account }),
         () => {},
      );
   }

   loadMoorings = () => {
      this.setState({ loading: true }, () => {
         getMyMooringsDetailed(
            this.getMyMooringsSuccess,
            this.getMyMooringsError,
            this.state.date,
         );
      });
   };

   extendMoorings(moorings) {
      const NoOrYes = this.props.t('global.NoOrYes');

      moorings.forEach((mooring) => {
         // List all renter names with exemption
         mooring.permanent_renter_names = mooring.renters
            .filter((renter) => renter.exemption == 0)
            .map((renter) => {
               var renter_name = `${renter.contact.lastname} ${renter.contact.firstname}`;
               if (renter.provisional == 1) renter_name = `(${renter_name})`;
               return renter_name;
            })
            .join(', ');

         // List all renter's phone numbers
         mooring.permanent_renter_phones = mooring.renters
            .filter((renter) => renter.exemption == 0)
            .map((renter) => {
               var renter_phone = `${renter.contact.mobile} ${renter.contact.phone_b} ${renter.contact.phone_p}`;
               return renter_phone;
            })
            .join(', ');

         // List all renter names with exemption
         mooring.exemption_renter_names = mooring.renters
            .filter((renter) => renter.exemption == 1)
            .map((renter) => {
               var renter_name = `${renter.contact.lastname} ${renter.contact.firstname}`;
               if (renter.provisional) renter_name = `(${renter_name})`;
               return renter_name;
            })
            .join(', ');

         // List all renter names with exemption
         mooring.renter_from_date = mooring.renters
            .filter((renter) => renter.exemption == 0)
            .map((renter) => `${renter.from_date}`)
            .join(', ');

         mooring.renter_to_date = mooring.renters
            .filter((renter) => renter.exemption == 0)
            .map((renter) => `${renter.to_date}`)
            .join(', ');

         //Formatting values
         mooring.power_string = NoOrYes[+mooring.power];
         mooring.water_string = NoOrYes[+mooring.water];
         mooring.buoy_string = NoOrYes[+mooring.buoy];
         mooring.app_visible_string = NoOrYes[+mooring.app_visible];
      });

      return moorings;
   }

   getMyMooringsSuccess = (moorings) => {
      console.log('got mooeings', moorings);
      const extendedMoorings = this.extendMoorings(moorings);

      const showEditButtons = moorings.some(function (mooring) {
         return mooring.rights.edit;
      });

      const filteredSortedMoorings = this.getFilteredRows(
         [...extendedMoorings],
         this.state.filters,
      );
      const sortedMoorings = this.getSortedRows(
         filteredSortedMoorings,
         this.state.columnSortings,
      );

      this.setState({
         moorings: extendedMoorings,
         sortedMoorings: sortedMoorings,
         loading: false,
         showEditButtons: showEditButtons,
      });
   };

   getMyMooringsError = (response) => {
      this.setState({
         loading: false,
         success: false,
         error: 'something went wrong',
      });
   };

   onRowsChange = (newRows, changedRow) => {
      if (this.state.editable) {
         const mooring = newRows[changedRow.indexes[0]];
         console.log('updating mooring:', mooring);
         this.setState({ sortedMoorings: newRows });
         updateMooring(
            mooring,
            (response) => {},
            () => {
               console.log('error');
            },
         );
      }
   };

   onRowClick = (arg1, arg2) => {};

   onSortColumnsChange = (columnSortings) => {
      const filteredSortedMoorings = this.getFilteredRows(
         [...this.state.moorings],
         this.state.filters,
      );
      const sortedMoorings = this.getSortedRows(
         filteredSortedMoorings,
         columnSortings,
      );

      this.setState({
         sortedMoorings: sortedMoorings,
         columnSortings: columnSortings,
      });

      // this.setState({ columnSortings: [], sortedMoorings: this.state.moorings })
   };

   getSortedRows = (moorings, columnSortings = this.state.columnSortings) => {
      var sortedMoorings = [...moorings];
      if (columnSortings && columnSortings[0]) {
         const columnKey = columnSortings[0].columnKey;
         const direction = columnSortings[0].direction;
         sortedMoorings.sort(function (a, b) {
            switch (columnKey) {
               case 'renter_from_date':
               case 'renter_to_date':
                  var comparison = a[columnKey] > b[columnKey] ? -1 : 1;
                  return direction === 'ASC' ? comparison : -comparison;
               default:
                  comparison = a[columnKey] > b[columnKey] ? -1 : 1;
                  return direction === 'ASC' ? comparison : -comparison;
            }
         });
      }
      return sortedMoorings;
   };

   getFilteredRows = (moorings, filters = this.state.filters) => {
      var filtered_moorings = [...moorings];
      if (filters.global) {
         filtered_moorings = deepSearchString(
            filtered_moorings,
            filters.global,
         );
      }
      return filtered_moorings;
   };

   onSelectedRowsChange = (arg1) => {
      var selectedMoorings = [];

      var selected_mooring_ids = Array.from(arg1);
      for (var i = 0; i < selected_mooring_ids.length; i++) {
         const mooring = this.state.moorings.find(
            (x) => x.mooring_id === selected_mooring_ids[i],
         );
         var selectedMooring = {
            berth_number: mooring.berth_number,
            harbour_name: mooring.harbour_name,
            renter_name: mooring.renter_name,
            exemption_renter_names: mooring.exemption_renter_names,
            renter_from_date: mooring.renter_from_date,
            renter_to_date: mooring.renter_to_date,
            real_length: mooring.real_length,
            real_beam: mooring.real_beam,
            max_length: mooring.max_length,
            max_beam: mooring.max_beam,
            max_draught: mooring.max_draught,
            pier_height: mooring.pier_height,
            power: mooring.power,
            water: mooring.water,
            buoy: mooring.buoy,
            mooring_type: mooring.mooring_type,
            mooring_zone: mooring.mooring_zone,
            marina_notes: mooring.marina_notes,
            additional_description: mooring.additional_description,
            confidential_description: mooring.confidential_description,
            app_visible: mooring.app_visible,
            daily_rate_default: mooring.daily_rate_default,
            country: mooring.country,
            renter_1_salutation: mooring.renters[0]
               ? mooring.renters[0].contact.salutation
               : '',
            renter_1_firstname: mooring.renters[0]
               ? mooring.renters[0].contact.firstname
               : '',
            renter_1_lastname: mooring.renters[0]
               ? mooring.renters[0].contact.lastname
               : '',
            renter_1_email: mooring.renters[0]
               ? mooring.renters[0].contact.email
               : '',
            renter_1_mobile: mooring.renters[0]
               ? mooring.renters[0].contact.mobile
               : '',
            renter_1_phone_p: mooring.renters[0]
               ? mooring.renters[0].contact.phone_p
               : '',
            renter_1_phone_b: mooring.renters[0]
               ? mooring.renters[0].contact.phone_b
               : '',
            renter_1_contact_id: mooring.renters[0]
               ? mooring.renters[0].contact.id
               : '',
            renter_1_street: mooring.renters[0]
               ? mooring.renters[0].contact.street
               : '',
            renter_1_zip: mooring.renters[0]
               ? mooring.renters[0].contact.zip
               : '',
            renter_1_city: mooring.renters[0]
               ? mooring.renters[0].contact.city
               : '',
            renter_1_country: mooring.renters[0]
               ? mooring.renters[0].contact.country
               : '',
            renter_1_birthday: mooring.renters[0]
               ? moment(mooring.renters[0].contact.birthday).format(
                    'DD.MM.YYYY',
                 )
               : '',
            renter_1_contact_type: mooring.renters[0]
               ? mooring.renters[0].contact.type
               : '',
            renter_1_notes: mooring.renters[0] ? mooring.renters[0].notes : '',
            renter_1_from_date: mooring.renters[0]
               ? moment(mooring.renters[0].from_date).format('DD.MM.YYYY')
               : '',
            renter_1_to_date: mooring.renters[0]
               ? moment(mooring.renters[0].to_date).format('DD.MM.YYYY')
               : '',
            renter_1_token: mooring.renters[0]
               ? mooring.renters[0].contact.token
               : '',
            registration_number:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.registration_number
                  : '',
            boat_name:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.name
                  : '',
            boat_type:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.type
                  : '',
            boat_make:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.make
                  : '',
            boat_length:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.length
                  : '',
            boat_beam:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.beam
                  : '',
            boat_draft:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.draft
                  : '',
            boat_country:
               mooring.renters[0] && mooring.renters[0].boat
                  ? mooring.renters[0].boat.country
                  : '',
            renter_2_salutation: mooring.renters[1]
               ? mooring.renters[1].contact.salutation
               : '',
            renter_2_firstname: mooring.renters[1]
               ? mooring.renters[1].contact.firstname
               : '',
            renter_2_lastname: mooring.renters[1]
               ? mooring.renters[1].contact.lastname
               : '',
            renter_2_email: mooring.renters[1]
               ? mooring.renters[1].contact.email
               : '',
            renter_2_mobile: mooring.renters[1]
               ? mooring.renters[1].contact.mobile
               : '',
            renter_2_phone_p: mooring.renters[1]
               ? mooring.renters[1].contact.phone_p
               : '',
            renter_2_phone_b: mooring.renters[1]
               ? mooring.renters[1].contact.phone_b
               : '',
            renter_2_contact_id: mooring.renters[1]
               ? mooring.renters[1].contact.id
               : '',
            renter_2_street: mooring.renters[1]
               ? mooring.renters[1].contact.street
               : '',
            renter_2_zip: mooring.renters[1]
               ? mooring.renters[1].contact.zip
               : '',
            renter_2_city: mooring.renters[1]
               ? mooring.renters[1].contact.city
               : '',
            renter_2_country: mooring.renters[1]
               ? mooring.renters[1].contact.country
               : '',
            renter_2_birthday: mooring.renters[1]
               ? moment(mooring.renters[1].contact.birthday).format(
                    'DD.MM.YYYY',
                 )
               : '',
            renter_2_contact_type: mooring.renters[1]
               ? mooring.renters[1].contact.type
               : '',
            renter_2_notes: mooring.renters[1] ? mooring.renters[1].notes : '',
            renter_2_from_date: mooring.renters[1]
               ? moment(mooring.renters[1].from_date).format('DD.MM.YYYY')
               : '',
            renter_2_to_date: mooring.renters[1]
               ? moment(mooring.renters[1].to_date).format('DD.MM.YYYY')
               : '',
            renter_2_token: mooring.renters[1]
               ? mooring.renters[1].contact.token
               : '',
            loan: mooring.loan,
            yearly_rate: mooring.yearly_rate,
            operating_cost: mooring.operating_cost,
            other_ops_cost: mooring.other_ops_cost,
            payment_currency: mooring.payment_currency,
            mooring_id: mooring.mooring_id,
         };
         selectedMoorings.push(selectedMooring);
      }

      this.setState({ selectedRows: arg1, selectedMoorings: selectedMoorings });
   };

   setFilters = (filters) => {
      const filtered = this.getFilteredRows(this.state.moorings, filters);
      const sortedFiltered = this.getSortedRows(
         filtered,
         this.state.columnSortings,
      );
      this.setState(
         { sortedMoorings: sortedFiltered, filters: filters },
         () => {
            this.searchInput.focus();
         },
      );
   };

   inputStopPropagation = (event) => {
      if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
         event.stopPropagation();
      }
   };
   exportToCSVClicked = () => {
      this.csvLink.link.click();
   };

   onDateChanged = (e) => {
      const date = moment(e.target.value);
      this.setState({ date: moment(date), loading: true }, () => {
         getMyMooringsDetailed(
            this.getMyMooringsSuccess,
            this.getMyMooringsError,
            date,
         );
      });
   };

   render() {
      const { t } = this.props;
      const filters = this.state.filters;
      const editable = this.state.editable;
      const NoOrYes = t('global.NoOrYes');
      const columns = [
         SelectColumn,
         // { key: 'mooring_id', name: 'ID', frozen: true, },
         {
            key: 'harbour_name',
            name: t(`${i18nPrefix}.harbour_name`),
            width: 150,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'berth_number',
            name: t(`${i18nPrefix}.berth_number`),
            width: 110,
            editor: TextEditor,
            frozen: true,
            editable: editable,
            formatter(props) {
               const value = props.row.berth_number;
               return (
                  <>
                     <a
                        href={`/home/mooringdetails/${props.row.mooring_id}`}
                        target='_blank'
                        rel='noreferrer'>
                        {' '}
                        {props.row.berth_number}{' '}
                     </a>
                  </>
               );
            },
         },
         {
            key: 'permanent_renter_names',
            name: t(`${i18nPrefix}.permanent_renter_names`),
            width: 110,
            editor: TextEditor,
            editable: false,
         },
         {
            key: 'exemption_renter_names',
            name: t(`${i18nPrefix}.exemption_renter_names`),
            width: 120,
            editor: TextEditor,
            editable: false,
         },
         {
            key: 'renter_from_date',
            name: t(`${i18nPrefix}.renter_from_date`),
            width: 110,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'renter_to_date',
            name: t(`${i18nPrefix}.renter_to_date`),
            width: 110,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'max_length',
            name: t(`${i18nPrefix}.max_length`),
            width: 100,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'max_beam',
            name: t(`${i18nPrefix}.max_beam`),
            width: 100,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'max_draught',
            name: t(`${i18nPrefix}.max_draught`),
            width: 100,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'pier_height',
            name: t(`${i18nPrefix}.pier_height`),
            width: 50,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'power_string',
            name: t(`${i18nPrefix}.power`),
            width: 50,
            editable: editable,
            editor: (p) => (
               <select
                  style={dropdown_style}
                  value={p.row.power_string}
                  onChange={(e) => {
                     console.log('e.target', e.target);
                     p.onRowChange(
                        {
                           ...p.row,
                           power_string: e.target.value,
                           power: NoOrYes.indexOf(e.target.value),
                        },
                        true,
                     );
                  }}>
                  <option key={0}>{this.props.t('global.no')}</option>
                  <option key={1}>{this.props.t('global.yes')}</option>
               </select>
            ),
         },
         {
            key: 'water_string',
            name: t(`${i18nPrefix}.water`),
            width: 50,
            editable: editable,
            editor: (p) => (
               <select
                  style={dropdown_style}
                  value={p.row.water_string}
                  onChange={(e) => {
                     console.log('e.target', e.target);
                     p.onRowChange(
                        {
                           ...p.row,
                           water_string: e.target.value,
                           water: NoOrYes.indexOf(e.target.value),
                        },
                        true,
                     );
                  }}>
                  <option key={0}>{this.props.t('global.no')}</option>
                  <option key={1}>{this.props.t('global.yes')}</option>
               </select>
            ),
         },
         {
            key: 'buoy_string',
            name: t(`${i18nPrefix}.buoy`),
            width: 50,
            editable: editable,
            editor: (p) => (
               <select
                  style={dropdown_style}
                  value={p.row.buoy_string}
                  onChange={(e) => {
                     console.log('e.target', e.target);
                     p.onRowChange(
                        {
                           ...p.row,
                           buoy_string: e.target.value,
                           buoy: NoOrYes.indexOf(e.target.value),
                        },
                        true,
                     );
                  }}>
                  <option key={0}>{this.props.t('global.no')}</option>
                  <option key={1}>{this.props.t('global.yes')}</option>
               </select>
            ),
         },
         {
            key: 'mooring_type',
            name: t(`${i18nPrefix}.mooring_type`),
            width: 150,
            editor: (p) => (
               <select
                  autoFocus
                  style={dropdown_style}
                  value={p.row.mooring_type}
                  onChange={(e) =>
                     p.onRowChange(
                        { ...p.row, mooring_type: e.target.value },
                        true,
                     )
                  }>
                  {this.state.mooring_types.map((mooring_type) => (
                     <option key={mooring_type.mooring_type}>
                        {mooring_type.mooring_type}
                     </option>
                  ))}
               </select>
            ),
         },
         {
            key: 'mooring_zone',
            name: t(`${i18nPrefix}.mooring_zone`),
            width: 150,
            editor: (p) => (
               <select
                  autoFocus
                  style={dropdown_style}
                  value={p.row.mooring_zone}
                  onChange={(e) =>
                     p.onRowChange(
                        { ...p.row, mooring_zone: e.target.value },
                        true,
                     )
                  }>
                  {this.state.mooring_zones.map((mooring_zone) => (
                     <option key={mooring_zone.id}>{mooring_zone.zone}</option>
                  ))}
               </select>
            ),
         },
         {
            key: 'marina_notes',
            name: t(`${i18nPrefix}.marina_notes`),
            width: 250,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'additional_description',
            name: t(`${i18nPrefix}.additional_description`),
            width: 250,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'confidential_description',
            name: t(`${i18nPrefix}.confidential_description`),
            width: 250,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'app_visible_string',
            name: t(`${i18nPrefix}.app_visible`),
            width: 150,
            editable: editable,
            editor: (p) => (
               <select
                  style={dropdown_style}
                  value={p.row.app_visible_string}
                  onChange={(e) => {
                     console.log('e.target', e.target);
                     p.onRowChange(
                        {
                           ...p.row,
                           app_visible_string: e.target.value,
                           app_visible: NoOrYes.indexOf(e.target.value),
                        },
                        true,
                     );
                  }}>
                  <option key={0}>{this.props.t('global.no')}</option>
                  <option key={1}>{this.props.t('global.yes')}</option>
               </select>
            ),
         },
         {
            key: 'daily_rate_default',
            name: t(`${i18nPrefix}.daily_rate_default`),
            width: 150,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'loan',
            name: t(`EditMooring.loan`),
            width: 150,
            editor: TextEditor,
            editable: editable,
         },
         {
            key: 'yearly_rate',
            name: t(`${i18nPrefix}.yearly_rate`),
            width: 150,
            editor: TextEditor,
            editable: false,
         },
         {
            key: 'operating_cost',
            name: t(`${i18nPrefix}.operating_cost`),
            width: 150,
            editor: TextEditor,
            editable: false,
         },
         {
            key: 'payment_currency',
            name: t(`${i18nPrefix}.payment_currency`),
            width: 150,
            editor: TextEditor,
            editable: false,
         },
      ];

      return (
         <Container style={{ maxHeight: 'none' }}>
            <Row>
               <Col sm={8}>
                  <div style={{ overflow: 'hidden' }}>
                     <h1 style={{ float: 'left' }}>
                        {t(`${i18nPrefix}.title`)}
                     </h1>
                     {this.state.loading && (
                        <div style={{ marginTop: 12 }}>
                           <Spinner
                              style={{ float: 'left', marginLeft: 10 }}
                              animation='border'
                           />
                        </div>
                     )}
                  </div>
               </Col>

               <Col sm={4}>
                  <Button
                     style={{ ...globalStyles.saveButton, marginLeft: 10 }}
                     disabled={this.state.loading}
                     onClick={() => this.exportToCSVClicked()}>
                     {t(`global.export`)}
                  </Button>
                  {this.state.showEditButtons && (
                     <Button
                        style={{ ...globalStyles.saveButton, marginLeft: 10 }}
                        disabled={this.state.loading}
                        onClick={() =>
                           this.setState({ showMassChangeModal: true })
                        }>
                        {t(`${i18nPrefix}.mass_edit`)}
                     </Button>
                  )}
                  {this.state.showEditButtons && (
                     <Button
                        style={
                           this.state.editable
                              ? globalStyles.redButton
                              : globalStyles.saveButton
                        }
                        disabled={this.state.loading}
                        onClick={() =>
                           this.setState({ editable: !this.state.editable })
                        }>
                        {this.state.editable
                           ? t(`${i18nPrefix}.editOn`)
                           : t(`${i18nPrefix}.editOff`)}
                     </Button>
                  )}
               </Col>
            </Row>

            <CSVLink
               separator={this.state.account.csv_separator}
               style={{ visibility: 'hidden' }}
               ref={(ref) => (this.csvLink = ref)}
               filename='BP-moorings.csv'
               data={this.state.selectedMoorings}>
               Download me
            </CSVLink>

            <Row>
               <Col md={9}>
                  <input
                     style={{
                        marginTop: 10,
                        outline: 'none',
                        marginBottom: 10,
                        width: '100%',
                        fintSize: 20,
                        border: 'none',
                        borderRadius: 0,
                        paddingLeft: 0,
                        backgroundColor: 'white',
                        color: COLORS.MAIN_BLUE,
                        boxShadow: 'none',
                     }}
                     autoFocus={true}
                     ref={(input) => {
                        this.searchInput = input;
                     }}
                     placeholder={t(`global.search`)}
                     value={filters.global}
                     onChange={(e) =>
                        this.setFilters({
                           ...filters,
                           global: e.target.value,
                        })
                     }
                  />
                  <div
                     style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: COLORS.GRAY_OUTLINE,
                     }}></div>
               </Col>
               <Col md={3}>
                  <FormInput
                     // label={t(`${i18nPrefix}.date`)}
                     type='date'
                     required
                     value={moment(this.state.date).format('YYYY-MM-DD')}
                     onChange={this.onDateChanged}
                  />
               </Col>
            </Row>
            <div style={{ marginTop: 10 }}></div>

            <Modal
               show={this.state.showMassChangeModal}
               onHide={() => this.setState({ showMassChangeModal: false })}>
               <Modal.Header closeButton>
                  <Modal.Title>{t(`${i18nPrefix}.mass_edit`)}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Row>
                     <Col md={6}>
                        <FormControl
                           ref={(input) => {
                              this.searchInput = input;
                           }}
                           placeholder={t(
                              `${i18nPrefix}.confidential_code_old`,
                           )}
                           value={filters.global}
                           onChange={(e) =>
                              this.setState({
                                 confidential_mass_change_old: e.target.value,
                              })
                           }
                        />
                     </Col>
                     <Col md={6}>
                        <FormControl
                           ref={(input) => {
                              this.searchInput = input;
                           }}
                           placeholder={t(
                              `${i18nPrefix}.confidential_code_new`,
                           )}
                           value={filters.global}
                           onChange={(e) =>
                              this.setState({
                                 confidential_mass_change_new: e.target.value,
                              })
                           }
                        />
                     </Col>
                  </Row>
               </Modal.Body>
               <Modal.Footer>
                  <Button
                     onClick={() => {
                        replaceConfidentialCode(
                           this.state.confidential_mass_change_old,
                           this.state.confidential_mass_change_new,
                           () => {
                              this.setState({
                                 confidential_mass_change_old: '',
                                 confidential_mass_change_new: '',
                              });
                              this.loadMoorings();
                              this.setState({ showMassChangeModal: false });
                           },
                           () => {},
                        );
                     }}>
                     {t(`global.apply`)}
                  </Button>
               </Modal.Footer>
            </Modal>

            <DataGrid
               style={{ maxHeight: 'none', height: 700 }}
               columns={columns}
               rows={this.state.sortedMoorings}
               className='rdg-light'
               onRowClick={this.onRowClick}
               onRowsChange={this.onRowsChange}
               rowKeyGetter={rowKeyGetter}
               defaultColumnOptions={{
                  sortable: true,
                  resizable: true,
                  editable: false,
               }}
               onSortColumnsChange={this.onSortColumnsChange}
               sortColumns={this.state.columnSortings}
               selectedRows={this.state.selectedRows}
               onSelectedRowsChange={this.onSelectedRowsChange}>
               <SortableHeaderCell />
            </DataGrid>
         </Container>
      );
   }
}

export default withTranslation()(MooringTalbe);
